import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Row, Col } from "antd";
import { PaymentFormWidget } from "../components/PaymentForm";
import { AccrualsWidget } from "../components/AccrualsWidget";
// import { PaymentsWidget } from "../components/PaymentsWidget";

const PaymentsTemplate = ({ paymentsForm, accrualsWidget }) => {
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col md={24} xl={24}>
          {paymentsForm}
        </Col>
        {/*<Col md={24} xl={12}>*/}
        {/*  {accrualsWidget}*/}
        {/*</Col>*/}
        {/*<Col md={24} xl={24}>*/}
        {/*  {paymentsWidget}*/}
        {/*</Col>*/}
      </Row>
    </>
  );
};

const Payments = () => {
  return (
    <MainLayout>
      <PageTemplate title={_t("Payments")}>
        <PaymentsTemplate
          paymentsForm={<PaymentFormWidget />}
          accrualsWidget={<AccrualsWidget />}
          // paymentsWidget={<PaymentsWidget />}
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default Payments;
